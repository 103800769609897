import axios from "axios";
import {User} from "../auth/useUser";

export interface HotelData {
  name: string,
  description: string,
  address: string,
  url: string,
  img: string,
  beds: number
}

export type Guest = {
  name: string,
  surname: string
}

export type Result = {
  rsvpId: number,
  rsvp: boolean,
  hotel: string | null
  updatedAt: string,
  guests: Guest[],
  shuttle: boolean | null,
  info: string
}

export const PROD_BASE_URL = "https://nina-und-simon-api.osim.at"
export const DEV_BASE_URL = "http://localhost:5000"
export const BASE_URL = PROD_BASE_URL

export const fetchAccommodations = async (user: User | null): Promise<HotelData[]> => {

  if (!user?.authToken) return []
  const response = await axios.get(
    `${BASE_URL}/api/rsvp/getAccommodations`,
    {
      headers: {
        "Authorization": "Bearer " + user.authToken
      }
    }
  );
  if (response.status == 200) {
    return response.data
  } else {
    throw Error("Failed to load data")
  }
}

export const fetchResponses = async (user: User | null): Promise<Result[]> => {
  {/*`https://nina-und-simon-api.osim.at/api/rsvp/responses`,*/
  }
  if (!user?.authToken) return []
  const response = await axios.get(
    `${BASE_URL}/api/rsvp/responses`,
    {
      headers: {
        "Authorization": "Bearer " + user.authToken
      }
    }
  );
  if (response.status == 200) {
    return response.data
  } else {
    throw Error("Failed to load data")
  }
}