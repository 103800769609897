import {ReactElement, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../auth/useAuth";
import axios from "axios";
import EditText from "../../components/EditText";
import Button from "../../components/Button";
import {BASE_URL} from "../../api/accommodations";


function Login(): ReactElement {
  const {user, login} = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("")

  const [name, setName] = useState("")
  const [password, setPassword] = useState("")

  const handleLogin = () => {
    setError("")
    console.log("Handle login")
    axios.post(`${BASE_URL}/api/signIn`, {
      "name": name,
      "password": password
    }).then(res => {
      if (res.status == 200) {
        login({
          id: "",
          name: name,
          email: "",
          authToken: res.data.accessToken,
        })
      }
    }).catch(err => {
      setError("Something went wrong")
    })

    //navigate("/", {replace: true})
  }

  return (
    <div>
      <div className={"container mx-auto px-8"}>
        <div className={"mb-16 max-w-96 mx-auto my-48"}>
          <div className={"text-2xl"}>Nina und Simon</div>
          <div className={"mt-8"}>
            <EditText
              className={""}
              id={"name"}
              label={"Name"}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeHolder={"Name"}/>
            <EditText
              password
              className={"mt-4"}
              id={"password"}
              label={"Passwort"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onSubmit={() => {
                handleLogin()
              }}
              placeHolder={"Passwort"}/>
            {/*
            <label htmlFor="name" className={"block text-sm font-medium leading-6 text-gray-900"}>Name</label>
            <div>
              <input type="text" name="name" id="name"
                     value={name}
                     onChange={e => setName(e.target.value)}
                     className={"block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-40 sm:text-sm"}/>
            </div>
            <label htmlFor="password" className={"block text-sm font-medium leading-6 text-gray-900"}>Password</label>
            <div>
              <input type="password" name="password" id="password"
                     value={password}
                     onChange={e => setPassword(e.target.value)}
                     className={"block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-40 sm:text-sm"}/>
            </div>
      */}
            {error && <div className={"text-red-900"}>Something went wrong</div>}
            <Button className={"mx-auto my-8"} onClick={() => handleLogin()} text={"Login"}/>

          </div>
        </div>
      </div>
    </div>)
}

export default Login