import React, {useState} from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Dashboard from "./screens/Dashboard";
import Accommodation from "./screens/Accomodation";
import Rsvp from "./screens/Rsvp";
import Wedding from "./screens/Wedding";

import {AuthContext} from "./context/AuthContext";
import {User} from "./auth/useUser";
import Login from "./screens/login/Login";
import Results from "./screens/Results";


function App() {

  const [user, setUser] = useState<User|null>(null)

  return (
    <AuthContext.Provider value={{user, setUser}}>
      <div className="">
        {user == null && <Login/>}
        {user != null &&
          <Routes>
            <Route path={"/"} element={<Dashboard/>}/>
            <Route path={"/accommodation"} element={<Accommodation/>}/>
            <Route path={"/rsvp"} element={<Rsvp/>}/>
            <Route path={"/responses"} element={<Results/>}/>
            <Route path={"/the-wedding"} element={<Wedding/>}/>
          </Routes>
        }
      </div>
    </AuthContext.Provider>
  );
}

export default App;
