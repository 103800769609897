import {ReactElement, useEffect, useRef, useState} from "react";
import Menu from "../../components/Menu";
import axios from "axios";
import Button from "../../components/Button";
import {useLocalStorage} from "../../auth/useLocalStorage";
import {useUser} from "../../auth/useUser";
import EditText from "../../components/EditText";


type AddGuestDialogProps = {
  addGuest: (guest: GuestData) => void,
  cancel: () => void
}

interface GuestData {
  name: string,
  surname: string
}


function AddGuestDialog(props: AddGuestDialogProps): ReactElement {

  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")


  function addGuest() {
    if (name.length > 0 && name.length < 20 && surname.length < 20) {
      props.addGuest({
        name: name, surname: surname
      })
    }
  }

  return (<div>
    <div className={"flex flex-row gap-4 flex-wrap"}>
      <EditText
        className={"max-w-64 flex-shrink-0"}
        id={"name"}
        label={"Name"}
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeHolder={"Name"}/>
      <EditText
        className={"max-w-64 flex-shrink-0"}
        id={"surname"}
        label={"Surname"}
        value={surname}
        onChange={(e) => setSurname(e.target.value)}
        placeHolder={"Surname"}
        onSubmit={() => addGuest()}
      />
    </div>
    <div className={""}>

      <Button
        small
        className={"self-end justify-self-end m-2 ml-0"}
        onClick={() => {
          props.cancel()
        }} text={"Cancel"}/>
      <Button
        small
        className={"self-end justify-self-end m-2"}
        onClick={() => {
          addGuest()
        }} text={"Save"}/>
    </div>
  </div>)

}

export default AddGuestDialog