import {ReactElement, useEffect, useState} from "react";
import axios from "axios";
import Button from "../../components/Button";
import {useUser} from "../../auth/useUser";
import AddGuestDialog from "./AddGuestDialog";
import AddAccommodationDialog, {AccommodationData} from "./AddAccomodationDialog";
import {useNavigate} from "react-router-dom";
import {BASE_URL} from "../../api/accommodations";

export type RsvpResult = {
  rsvp: boolean,
  guests: number,
  hotel?: string | null,
  shuttle: boolean
  info: string
}

type RsvpFormProps = {
  changeGuest: () => void,
  onSuccess: (rsvp: RsvpResult) => void,
  guest: RsvpData
}

interface GuestData {
  name: string,
  surname: string
}

interface RsvpData extends GuestData {
  id: number,
  rsvpId: number,
}


function RsvpForm(props: RsvpFormProps): ReactElement {
  const user = useUser()
  const [isAddingGuest, setIsAddingGuest] = useState(false)
  const [isAddingAccommodation, setIsAddingAccommodation] = useState(false)
  const [hasLoadingError, setLoadingError] = useState(false)
  const [response, setResponse] = useState(true)

  const [hasLoaded, setLoaded] = useState(false)
  const [guestList, setGuestList] = useState<GuestData[]>([])
  const [accommodation, setAccommodation] = useState<string | null>(null)
  const [shuttle, setShuttle] = useState<boolean>()
  const [info, setInfo] = useState("")
  const [didSendRsvp, setDidSendRsvp] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (!user.user?.authToken) return
      console.log("Req " + props.guest.rsvpId)
      const response = await axios.get(
        `${BASE_URL}/api/rsvp`,
        {
          params: {
            rsvpId: props.guest.rsvpId
          },
          headers: {
            "Authorization": "Bearer " + user.user.authToken
          }
        }
      );
      if (response.status == 200) {
        console.log(JSON.stringify(response.data))
        const guests = response.data.guests as RsvpData[] || [] as RsvpData[]
        setGuestList(guests.filter(g => g.id !== props.guest.id))
        setAccommodation(response.data.hotel || null)
        setShuttle(response.data.shuttle)
        setInfo(response.data.info)
        setLoaded(true)
        console.log(response.data.rsvp)
        setResponse(response.data.rsvp === undefined ? true : response.data.rsvp)
        setDidSendRsvp(response.data.updatedAt !== undefined && response.data.updatedAt !== null)
      } else {
        setLoadingError(true)
      }
    }
    fetchData().catch((e) => {
      setLoadingError(true)
    })
  }, [user.user]);

  async function sendRsvp() {
    if (!user.user?.authToken) return
    const rsvpData = {
      rsvpId: props.guest.rsvpId,
      rsvp: response,
      guests: [
        props.guest,
        ...guestList
      ],
      hotel: accommodation,
      shuttle: shuttle,
      info: info,

    }
    console.log(rsvpData)
    try {
      const res = await axios.post(
          `${BASE_URL}/api/rsvp/submit`,
          rsvpData,
          {
            headers: {
              "Authorization":
                "Bearer " + user.user.authToken
            }
          }
        )
      ;
      if (res.status == 200) {
        console.log("DONE")
        props.onSuccess({
          guests: rsvpData.guests.length,
          hotel: rsvpData.hotel,
          info: rsvpData.info,
          rsvp: rsvpData.rsvp,
          shuttle: false

        })
      } else {
        setLoadingError(true)
      }
    } catch (e) {
      console.log(e)
      setLoadingError(true)
    }
  }

  return (
    <div>
      {hasLoaded ?
        <div>
          <div className={"inline m-4"}>
            {props.guest.name} {props.guest.surname}</div>
          <Button
            small
            onClick={() => {
              props.changeGuest()
            }} text={"Ändern"}/>
          {didSendRsvp && <div className={"m-4"}>Wir haben bereits eine Antwort von dir erhalten.</div>}
          <div className={"my-12 p-2"}>
            Meine Antwort:
            <div className={"m-2 flex flex-row"}>
              <input className={"cursor-pointer"} type="radio" id="attending-yes" value="true" name="attending"
                     checked={response} onChange={() => setResponse(true)}
              />
              <label className={"p-2 cursor-pointer"} htmlFor="attending-yes">Ja, ich komme gerne</label>
            </div>
            <div className={"m-2 flex flex-row"}>
              <input className={"cursor-pointer"} type="radio" id="attending-no" value="false" name="attending"
                     checked={!response} onChange={() => setResponse(false)}
              />
              <label className={"p-2 cursor-pointer"} htmlFor="attending-no">Nein, muss leider absagen</label>
            </div>

            <div className={"mt-12 flex flex-col"}>
              <h2 className={"mb-2"}>Gäste (gesamt: {1 + guestList.length})</h2>
              <div className={"mb-2"}>
                <ul>
                  {[props.guest, ...guestList].map(guest =>
                    <li key={guest.name + guest.surname}
                        className={"flex flex-row border-b py-1.5"}
                    >
                      {guest.name} {guest.surname}
                      <div className={"flex-1"}/>
                      {guest === props.guest || isAddingGuest ||
                        <Button small onClick={() => {
                          setGuestList(guestList.filter(g => {
                            return g !== guest
                          }))
                        }} text={"Entfernen"}/>
                      }
                    </li>)
                  }
                </ul>
              </div>
              {
                isAddingGuest &&
                <AddGuestDialog addGuest={(guest) => {
                  setGuestList([...guestList, guest])
                  setIsAddingGuest(false)
                }} cancel={() => setIsAddingGuest(false)}/>
              }
              {
                !isAddingGuest &&
                <Button
                  small
                  className={"mt-3 self-end"}
                  onClick={() => {
                    setIsAddingGuest(true)
                  }} text={"Hinzufügen"}/>
              }
            </div>
            <div className={"mt-12 flex flex-col"}>
              <h2 className={"mb-2"}>Unterkunft</h2>
              <p className={"text-sm mb-2"}>Wir haben Zimmer vorreserviert und werden eure Infos an das Hotel
                weitergeben.</p>
              {accommodation && accommodation.length > 0 && <div className={"flex flex-row my-2"}>
                <div>
                  <h3>Zimmeranfrage</h3>
                  {accommodation} - 2 Betten
                  <div className={"text-sm"}>{shuttle?"Mit Taxi":"Ohne Taxi"} zum Hotel</div>
                </div>
                <div className={"flex-1"}/>
                <Button className={"self-center"} onClick={() => {
                  setAccommodation(null)
                }} text={"Entfernen"} small/>
              </div>}
              {isAddingAccommodation &&
                <div className={"mb-2"}>
                  <AddAccommodationDialog addAccommodation={(data) => {
                    setAccommodation(data.name)
                    setShuttle(data.shuttle)
                    setIsAddingAccommodation(false)
                  }} cancel={() => {
                    setIsAddingAccommodation(false)
                  }} numGuests={guestList.length}/>
                </div>
              }
              {!isAddingAccommodation && (accommodation === null || accommodation.length <= 0) &&
                <Button
                  small
                  className={"self-end"}
                  onClick={() => {
                    setIsAddingAccommodation(true)
                  }} text={"Hinzufügen"}/>
              }
            </div>


            <div className={"mt-8"}>
              <h2 className={"mb-2"}>Sonstige Information</h2>
              <p className={"text-sm"}>Bitte gib uns bescheid, wenn du besondere Wünsche bezüglich Essen oder Unterkunft
                hast.</p>
              <div className={"my-2"}>
          <textarea name="food" id="food" className={"w-full p-1"} rows={10} onChange={e => {
            setInfo(e.target.value)
          }} value={info}/>
              </div>
            </div>

            <Button
              disabled={isAddingAccommodation}
              onClick={() => {
              sendRsvp()
            }} text={"Abschicken"}></Button>
          </div>
        </div> :

        <div className={"text-center"}>
          Loading...
        </div>}
    </div>
  )

}

export default RsvpForm