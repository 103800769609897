import {Link} from "react-router-dom";
import {ReactElement} from "react";


function Menu(): ReactElement {
  return <div></div> /*(<menu className={"flex flex-row justify-center"}>
    <li className={"mx-2"}><Link to={"/"}>Home</Link></li>
    <li className={"mx-2"}><Link to={"/rsvp"}>RSVP</Link></li>
    <li className={"mx-2"}><Link to={"/the-wedding"}>The Wedding</Link></li>
    <li className={"mx-2"}><Link to={"/accommodation"}>Accommodation</Link></li>
  </menu>)
  */
}

export default Menu