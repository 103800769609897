import {ReactElement, useEffect, useState} from "react";
import Menu from "../../components/Menu";
import {fetchAccommodations, fetchResponses, HotelData, Result} from "../../api/accommodations";
import {useUser} from "../../auth/useUser";
import {findAllByDisplayValue} from "@testing-library/react";


type ResultsProps = {}


function Results(props: ResultsProps): ReactElement {
  const user = useUser()
  const [responses, setResponses] = useState<Result[]>()

  useEffect(() => {
    fetchResponses(user.user)
      .then(result => {
        console.log(JSON.stringify(result))
        setResponses(result)
      })
      .catch(e => {
        console.log("Failed loading accommodations")
      })
  }, [user.user]);

  return (
    <div>
      <Menu/>
      <div className={"mx-auto my-16"}>
        <div className={"text-center mb-16 mx-8"}>
          <h1 className={"text-3xl"}>Responses</h1>
          <div className={"my-8"}>

          </div>
          <div>
            {
              responses?.map(response =>
                <div className={"grid md:grid-cols-10 gap-4 border items-center p-2 text-left"}>
                  <div className={"flex flex-col col-span-2"}>
                    {response.guests.map(guest => {
                      return <div>{guest.name} {guest.surname}</div>
                    })}
                  </div>
                  <div className={""}>{response.rsvp ? "Accepted" : "Declined"}</div>
                  <div className={"col-span-2"}>{response.hotel}</div>
                  <div>{response.shuttle ? "shuttle" : "-"}</div>
                  <div className={"col-span-4 text-left"}>{response.info}</div>

                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>)
}

export default Results