import {ReactElement, useEffect, useState} from "react";
import {useLocalStorage} from "../../auth/useLocalStorage";
import Button from "../Button";
import {AdvancedMarker, APIProvider, Map, Pin} from "@vis.gl/react-google-maps";

export type Position = {
  lat: number,
  lng: number
}

export type MapProps = {
  apiKey: string,
  position: Position
}

const MAP_KEY = "map-accepted"

function MapContainer(props: MapProps): ReactElement {

  const {getItem, setItem} = useLocalStorage();
  const [mapAccepted, setMapAccepted] = useState(false)

  useEffect(() => {
    if (getItem(MAP_KEY) === "true") {
      setMapAccepted(true)
    }
  }, [])

  return (
    <div className={"my-2 flex flex-col mx-8"}>
      <div className={"h-96"}>
        {(mapAccepted &&

            <APIProvider apiKey={props.apiKey}>
              <Map
                mapId={"Haggenberg"}
                defaultCenter={{lat: props.position.lat - 0.12, lng: props.position.lng - 0.2}}
                defaultZoom={9}
                gestureHandling={'greedy'}
                disableDefaultUI={true}>
                <AdvancedMarker key={"Haggenberg"} position={props.position}>
                  <Pin background={"#FFF"} borderColor={"#000"} glyphColor={"#F7F5EF"}/>
                </AdvancedMarker>
              </Map>
            </APIProvider>)
          ||
          <div className={"flex flex-col items-center h-full justify-center bg-gray-200"}>
            <Button className={"m-8 text-sm"} onClick={() => {
              setItem(MAP_KEY, "true")
              setMapAccepted(true)
            }} text={"Google Maps aktivieren"}/>
            <p className={"max-w-xl text-center mx-4"}>Durch das aktivieren von Google Maps werden Daten mit Google
              ausgetauscht. Diese Einstellung wird als
              Cookie gespeichert.</p>
          </div>
        }
      </div>
      <Button className={"max-w-96 m-8 self-center text-sm"} onClick={() => {
        window.open("https://www.google.com/maps/place/Castle+Hagenberg/@48.6319336,16.4475095,17z/data=!4m6!3m5!1s0x476d39819e8ad68d:0x49db9378078216a1!8m2!3d48.6319301!4d16.4500844!16s%2Fg%2F11hythyjxk?entry=ttu", '_blank')
      }} text={"In Google Maps öffnen"}/>
    </div>)
}

export default MapContainer