import {Link} from "react-router-dom";
import {ReactElement} from "react";


export type ProgramItemProps = {
  time: string;
  title: string;
  desc: string;
}

function ProgramItem(props: ProgramItemProps): ReactElement {
  return (
    <div className={"my-8 flex flex-row"}>
      <span className={"text-2xl lg:text-4xl  font-serif"}>{props.time}</span>
      <div className={" ml-8"}>
        <span className={"text-2xl lg:text-4xl font-serif"}> {props.title}</span>
        <p className={""}> {props.desc}</p>
      </div>
    </div>)
}

export default ProgramItem