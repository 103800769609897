import {ReactElement} from "react";
import Button from "../../components/Button";
import {RsvpResult} from "./RsvpForm";
import {useNavigate} from "react-router-dom";

export type RsvpSuccessProps = {
  success: boolean
  rsvp: RsvpResult
}

function RsvpSuccessMessage(props: RsvpSuccessProps): ReactElement {
  const navigate = useNavigate()
  return <div className={"text-center"}>
    <h1 className={"font-serif text-3xl"}>Vielen Dank für deine Antwort</h1>
    {props.rsvp.rsvp ? <div>Wir freuen uns auf dich!</div> : <div>Schade, dass du es nicht schaffst.</div>}
    <Button className={"mt-8"} text={"Zur Startseite"} onClick={() => {
      navigate("/")
    }}/>
  </div>
}

export default RsvpSuccessMessage