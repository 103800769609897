import {ReactElement} from "react";

type ButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  text: string
  className?: string | undefined
  small?: boolean
  disabled?: boolean
}

function Button(props: ButtonProps): ReactElement {
  let size
  if (props.small) {
    size = "px-4 py-1 text-sm"
  } else {
    size = "px-8 py-2"
  }
  let enabled = props.disabled === undefined || !props.disabled
  return (
    <button
      className={size + (enabled ? " hover:bg-black/5 border-gray-900" : " bg-black/10 text-black/40 border-gray-900/50") + " border " + props.className}
      onClick={
        enabled ? props.onClick : () => {
        }
      }>
      {props.text}
    </button>)
}

export default Button