import {ReactElement, useEffect, useRef, useState} from "react";
import Menu from "../../components/Menu";
import axios from "axios";
import Button from "../../components/Button";
import {useLocalStorage} from "../../auth/useLocalStorage";
import {useUser} from "../../auth/useUser";
import EditText from "../../components/EditText";
import RsvpForm, {RsvpResult} from "./RsvpForm";
import RsvpSuccessMessage from "./RsvpSuccess";
import {useNavigate} from "react-router-dom";
import {BASE_URL} from "../../api/accommodations";


type RsvpProps = {}

interface GuestData {
  name: string,
  surname: string
}

interface RsvpData extends GuestData {
  id: number,
  rsvpId: number,
}


function Rsvp(props: RsvpProps): ReactElement {
  const user = useUser()
  const navigate = useNavigate()
  const [name, setName] = useState<string>(user.user?.name || "")
  const abortControllerRef = useRef<AbortController | null>(null);
  const [availableGuests, setAvailableGuests] = useState<RsvpData[]>([])
  const [noGuest, setNoGuests] = useState(false)
  const [guest, setGuest] = useState<RsvpData | null>(null)
  const [hasLoadingError, setLoadingError] = useState(false)

  const [rsvp, setRsvp] = useState<RsvpResult>()

  useEffect(() => {
    // Debounce to avoid too many requests
    const delayDebounceFn = setTimeout(async () => {
      if (name.length >= 3) {
        await fetchData(name);
      }
    }, 500); // 500ms debounce time

    return () => {
      clearTimeout(delayDebounceFn);
      // Cancel the previous fetch request if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [name]);


  const fetchData = async (searchQuery: string) => {
    if (!user.user?.authToken) return
    setLoadingError(false)
    abortControllerRef.current = new AbortController();
    const {signal} = abortControllerRef.current;
    try {
      const response = await axios.post(
        `${BASE_URL}/api/rsvp/searchGuest`,
        {
          name: searchQuery
        },
        {
          signal: signal,
          headers: {
            "Authorization": "Bearer " + user.user.authToken
          }
        }
      );

      if (response.status === 200) {
        console.log(response.data)
        const guests = response.data as RsvpData[]
        setAvailableGuests(guests.sort((a, b) => {
          const aName = a.name.toLowerCase()
          const bName = b.name.toLowerCase()
          if (aName < bName) return -1
          if (aName > bName) return 1
          const aSur = a.surname.toLowerCase()
          const bSur = b.surname.toLowerCase()
          if (aSur < bSur) return -1
          if (aSur > bSur) return 1
          return 0
        }))
        setNoGuests(response.data.length == 0)
      } else {
        setLoadingError(true)
        console.error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setLoadingError(true)
        console.error(error);
      }
    }
  };

  let stage

  if (rsvp) {
    stage = <RsvpSuccessMessage success={true} rsvp={rsvp}/>
  } else if (guest) {
    stage = <RsvpForm changeGuest={() => setGuest(null)} guest={guest} onSuccess={
      (result) => {
        setRsvp(result)
      }
    }/>
  } else {
    stage = <div>
      <EditText
        className={"mb-4 max-w-64"}
        id={"name"}
        label={"Name"}
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeHolder={"Name"}/>

      {hasLoadingError &&
        <div>
          <p>That didn't work :-(</p><p> Please try again or come back later</p>
        </div>
      }

      <div className={"max-w-64"}>
        {noGuest && <div>
          Keinen Gast gefunden. Versuche eine andere Eingabe.
        </div>}
        <ul>
          {availableGuests.map(guest => {
            return <li className={"hover:bg-gray-700/10 p-4 border-t border-b border-gray-700/20 cursor-pointer"}
                       key={"guest-" + guest.id} onClick={event => setGuest(guest)}>
              <div className={"flex flex-row"}>
                {guest.name} {guest.surname}
                <div className={"flex-1"}/>
                <img className={"inline "} src="/pictures/arrow_forward.svg"
                     alt="Select guest"/>
              </div>
            </li>
          })}
        </ul>
      </div>
    </div>
  }

  return (
    <div className={"m-4"}>
      <div className={"container mx-auto max-w-2xl shadow my-16 pt-4 pb-16 px-4 min-h-96"}>
        <div className={"mb-4 flex flex-row justify-end"}>
          <img onClick={()=>{
            navigate("/")
          }} className={"cursor-pointer px-4 py-4"} src="/pictures/close.svg" alt="Schließen"/>
        </div>
        <h1 className={"text-center text-5xl font-['Italianno'] mb-8"}>RSVP</h1>

        {stage}
      </div>
    </div>)
}

export default Rsvp