import React, {ReactElement} from "react";

type EditTextProps = {
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  label: string
  value: string,
  id: string
  placeHolder: string,
  className?: string | undefined
  onSubmit?: () => void
  password?:boolean
}

function EditText(props: EditTextProps): ReactElement {
  return (
    <div className={props.className}>
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={props.id}>
        {props.label}
      </label>
      <input
        type={props.password===true?"password":"text"}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id={props.id}
        placeholder={props.placeHolder}
        value={props.value}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            if (props.onSubmit) props.onSubmit()
          }
        }}
        onChange={props.onChange}
      />
    </div>)
}

export default EditText