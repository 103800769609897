import {ReactElement} from "react";
import Menu from "../../components/Menu";


type WeddingProps = {}

function Wedding(props: WeddingProps): ReactElement {
  return (
    <div>
      <Menu/>
      <div className={"container mx-auto"}>
        <div className={"text-center mb-16 mx-8"}>
          <div className={"text-2xl"}>The Wedding</div>
        </div>
      </div>
    </div>)
}

export default Wedding