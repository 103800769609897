import {ReactElement, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Menu from "../../components/Menu";
import ProgramItem from "../../components/time/ProgramItem";
import Button from "../../components/Button";
import MapContainer from "../../components/Map";
import {useUser} from "../../auth/useUser";
import {BASE_URL} from "../../api/accommodations";


type DashboardProps = {}

type CombinedData = {
  dashboard: DashboardData
  dresscode: Content,
  stay: Content
}

type Content = {
  title: string,
  text: string[]
}
type DashboardData = {
  title: string,
  subtitle: string,
  heroImage: string,
  heroImageAlt: string
}

function renderContent(content: Content) {
  return <div className={"mt-24 mx-8 text-center font-sans-serif text-xl flex flex-col items-center"}>
    <h2 className={"font-['Italianno'] text-7xl lg:text-7xl my-4"}>{content.title}</h2>
    {content.text.map(t => <p className={"max-w-2xl"}>{t}</p>)}
  </div>
}

function Dashboard(props: DashboardProps): ReactElement {

  const [data, setData] = useState<CombinedData>()
  const [error, setError] = useState(false)
  const user = useUser()

  const navigate = useNavigate()

  useEffect(() => {
    console.log("LOADING DATA")
    if (!user.user?.authToken) return
    setError(false)
    fetch(`${BASE_URL}/api/home`, {
      headers: {
        "Authorization": "Bearer " + user.user.authToken
      }
    })
      .then(async res => setData(await res.json()))
      .catch(() => setError(true))
  }, [user.user])

  const logout = () => {
    user.removeUser()
  };

  return (
    <div className={""}>
      {data != undefined &&
        <div className={"mx-auto col-start-1 row-start-1 relative bg-white/40 mb-16 pb-16"}>
          <a href="/">
            {/* <div className={"font-['Italianno'] text-2xl p-4"}>N & S</div>*/}
          </a>

          {/* ================== Logout ================== */}
          <div className={"flex flex-row"}>
            <div className={"flex-1"}></div>
            <div className={"p-4 cursor-pointer hover:scale-110 flex flex-row items-center opacity-60"} onClick={() => logout()}>
              <img src="/pictures/logout.svg" className={"w-8 h-8 "} alt="Abmelden"/>
              <div className={"hidden lg:block"}>Abmelden</div>
            </div>
          </div>
          {/* ================== We're getting married ================== */}
          <div className={"pt-16"}>
            <div className={"mb-8 mx-6 md:mx-24  font-serif"}>
              <div className={"text-2xl md:text-5xl"}>{data.dashboard.subtitle}</div>
              <h1 className={"text-5xl md:text-9xl"}>{data.dashboard.title}</h1>
            </div>
          </div>
          {/* ================== RSVP + PHOTO ================== */}
          <div className={"flex m- flex-col md:flex-row md:mx-auto lg:relative lg:z-10 lg:top-16"}>
            <div className={"basis-1/3 m-4 md:ml-24"}>
              <div className={"text-xl"}>Samstag, 21. Sept., 2024<br/>Schloss Haggenberg,<br/>2133 Hagenberg</div>
              <div className={""}>
                <Button className={"mt-8"} text={"RSVP"} onClick={() => navigate("/rsvp")}/>
              </div>
            </div>
            <div className={"basis-2/3 md:pr-24"}>
              <img className={"object-center"} src={data.dashboard.heroImage} alt={data.dashboard.heroImageAlt}/>
            </div>
          </div>


          <div className={"flex flex-col lg:flex-row bg-gray-200 py-16 lg:py-24 lg:relative lg:-top-16"}>
            <div className={"flex-auto basis-1/2 flex flex-col xl:px-24"}>
              <h2 className={"place-self-start mt-12 my-8 mx-6 text-4xl 2xl:text-6xl font-serif"}>Celebrating Our
                Love<br/>With
                Those
                We Love</h2>
              <img className={"m-6 lg:mr-24"} src="/pictures/schloss_haggenberg_1.webp" alt="Schloss Haggenberg"/>
            </div>
            {/* ================== OUR DAY ================== */}
            <div className={"flex-auto basis-1/2 self-center mx-8 xl:pr-56"}>
              <ProgramItem time={"13:00"}
                           title={"Shuttle bus departure from Laa or individual travel to Schloss Haggenberg"}
                           desc={""/*"Shuttle bus departure from Laa or own travel to Castle Haggenberg."*/}/>
              <ProgramItem time={"13:30"} title={"Reception and fingerfood"}
                           desc={""}/>
              <ProgramItem time={"15:00"} title={"Coffee and cake"}
                           desc={""/*"Coffee and cake raditionally served at 3´."*/}/>
              <ProgramItem time={"17:00"} title={"Wedding Ceremony"} desc={""}/>
              <ProgramItem time={"19:00"} title={"Dinner"} desc={""}/>
              <ProgramItem time={"21:00"} title={"Cocktails & Music"}
                           desc={""/*"Celebrate with us as Redmosphere sets the perfect tone for our special day with their enchanting live performance!"*/}/>

              {
                /*
                <ProgramItem time={"13:00"} title={"Arrival at Location"}
                           desc={""}/>
              <ProgramItem time={"13:30"} title={"Reception and fingerfood"}
                           desc={""}/>
              <ProgramItem time={"15:00"} title={"Coffee and cake"}
                           desc={""}/>
              <ProgramItem time={"17:00"} title={"Wedding Ceremony"} desc={""}/>
              <ProgramItem time={"19:00"} title={"Dinner"} desc={""}/>
              <ProgramItem time={"21:00"} title={"Cocktails & Music"}
                           desc={""}/>
                 */
              }
            </div>
          </div>


          {/* ================== Dresscode ================== */}
          <div>
            {renderContent(data.dresscode)}
            {renderContent(data.stay)}
          </div>

          <div className={"flex mt-24 py-4 lg:p-4 bg-white md:flex-row flex-col"}>
            <div
              className={"w-full object-cover aspect-square ease-in-out hover:scale-110 duration-300 bg-white py-2 md:px-2"}>
              <a href="/pictures/nina-simon-leaning.jpg">
                <img className={"w-full object-cover aspect-square"} src="/pictures/nina-simon-leaning.jpg" alt=""/>
              </a>
            </div>
            <div
              className={"w-full object-cover aspect-square ease-in-out hover:scale-110 duration-300 bg-white py-2 md:px-2"}>
              <a href="/pictures/nina-simon-ring.jpg">
                <img className={"w-full object-cover aspect-square"} src="/pictures/nina-simon-ring.jpg" alt=""/>
              </a>
            </div>
            <div
              className={"w-full object-cover aspect-square ease-in-out hover:scale-110 duration-300 bg-white py-2 md:px-2"}>
              <a href="/pictures/nina-simon-kiss.jpg">
                <img className={"w-full object-cover aspect-square"} src="/pictures/nina-simon-kiss.jpg" alt=""/>
              </a>
            </div>
          </div>

          <div className={"mt-40 flex flex-col lg:flex-row"}>
            <div className={"flex flex-col items-center flex-1 mb-16"}>
              <h2 className={"font-['Italianno'] text-7xl lg:text-7xl my-4"}>Location</h2>
              <p>Schloss Haggenberg<br/>2133 Hagenberg</p>
            </div>
            <div className={"flex-1"}>
              <div className={"lg:mr-40"}>
                <MapContainer apiKey={"AIzaSyDgSje2qnSJrOgYcDaXxb1N2syHCGk8Fb0"}
                              position={{lat: 48.63194705224574, lng: 16.45020617506664}}/>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Dashboard