import {ReactElement, useEffect, useState} from "react";
import axios from "axios";
import Button from "../../components/Button";
import {useUser} from "../../auth/useUser";
import {BASE_URL, HotelData} from "../../api/accommodations";


type AddAccommodationProps = {
  addAccommodation: (accommodation: AccommodationData) => void,
  cancel: () => void,
  numGuests: number
}

export interface AccommodationData {
  name: string,
  shuttle: boolean
}




function AddAccommodationDialog(props: AddAccommodationProps): ReactElement {
  const user = useUser()
  const [name, setName] = useState("")
  const [beds, setBeds] = useState(props.numGuests)
  const [hotels, setHotels] = useState<HotelData[]>()

  const [hasLoadingError, setLoadingError] = useState(false)
  const [taxi, setTaxi] = useState(true)

  useEffect(() => {
    const fetchData = async () => {

      if (!user.user?.authToken) return
      const response = await axios.get(
        `${BASE_URL}/api/rsvp/getAccommodations`,
        {
          headers: {
            "Authorization": "Bearer " + user.user.authToken
          }
        }
      );
      if (response.status == 200) {
        setHotels(response.data)
      } else {
        setLoadingError(true)
      }
    }
    fetchData().catch((e) => {
      setLoadingError(true)
    })
  }, [user.user]);


  function addAccommodation() {
    if (name.length > 0 && beds > 0) {
      props.addAccommodation({
        name: name,
        shuttle: taxi
      })
    }
  }

  return (<div>
    {hasLoadingError && <div><p>Something went wrong :-(</p><p>Please try again or come back later.</p></div>}

    {hotels &&
      <div>
        <ul>
          {hotels.map(hotel => <li key={hotel.name}>
            <div className={"min-h-24 flex flex-col my-6 border-b"}>
              <div className={"flex flex-row"}>
                <div className={"flex-shrink-0"}>
                  {/* ===================== Hotel Foto ===================== */}
                  {hotel.img &&
                    <a href={hotel.url} target="_blank"><img className={"h-32 w-20 object-cover"} src={hotel.img}
                                                             alt={hotel.name}/></a>}
                </div>
                {/* ===================== Hotel Beschreibung ===================== */}
                <div className={"flex flex-col ml-2"}>
                  <div>
                    {hotel.url ?
                      <a className={"underline mt-2"} href={hotel.url}>{hotel.name}</a> :
                      <div className={"font-medium mt-2"}>{hotel.name}</div>
                    }

                  </div>

                  <div>
                    <p className={"text-sm"}>{hotel.description}</p>
                  </div>
                  <div>
                    {hotel.address}
                  </div>
                </div>
              </div>
              <div className={"my-2"}>
                <input className={"cursor-pointer"} type="radio" id={hotel.name} value={hotel.name} name="hotel"
                       onChange={() => {
                         setName(hotel.name)
                         setBeds(2)
                       }}
                       checked={name === hotel.name}/>
                <label className={"p-2 cursor-pointer"} htmlFor={hotel.name}>{hotel.beds}</label>
                {/*<label className={"p-2 cursor-pointer"} htmlFor="attending-yes">Yes, I'll be there</label>*/}
              </div>

            </div>
          </li>)}
        </ul>
        <div>
          <h3>Hotel - Taxi</h3>
          <p>Es gibt einen Taxi-Service der Gäste um 13:00 vom Hotel zum Schloss und in der Nacht zurück bringt.</p>
          <p>Bei Interesse bitte auswählen:</p>
          <div className={"m-2"}>
            <input className={"cursor-pointer"} type="checkbox" id={"taxi"} checked={taxi}
                   onChange={e => setTaxi(e.target.checked)} name={"taxi"}/>
            <label className={"p-2 cursor-pointer"} htmlFor="taxi">Interesse an Taxi-Service</label>
          </div>
        </div>
      </div>
    }

    <div className={""}>

      <Button
        small
        className={"self-end justify-self-end m-2 ml-0"}
        onClick={() => {
          props.cancel()
        }} text={"Abbrechen"}/>

      <Button
        small
        className={"self-end justify-self-end m-2"}
        onClick={() => {
          addAccommodation()
        }} text={"Speichern"}/>
    </div>
  </div>)

}

export default AddAccommodationDialog